import React, { useState } from 'react';
import postService from '../../services/PostService';

const ApprovePost = ({ post, isOpen, onClose, onApproveSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleApprovePost = async () => {
        setLoading(true);
        try {
            let result=await postService.updatePostStatus(post._id,{"approved":true, "status":"approved"});
            // Replace with the actual service method for approving posts
            onApproveSuccess(result); // Notify parent component of successful approval
            setError(null);
            onClose(); // Close the modal
        } catch (error) {
            setError('Failed to approve the post. Please try again.'); // Set error message
            console.error('Error approving post:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
            <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
            <div className="bg-white p-6 rounded-lg z-10 w-96">
                <h2 className="text-lg font-semibold mb-4">Approve Post</h2>
                <p className="mb-4">Are you sure you want to approve this post?</p>
                {error && (
                    <div className="text-red-600 mb-4">{error}</div>
                )}
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleApprovePost}
                        className={`bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? 'Approving...' : 'Approve Post'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ApprovePost;
