// src/services/postService.js
import axiosInstance from "../utils/AxiosInterceptor";

const postService = {
  createPost: async (postData) => {
    try {
      const response = await axiosInstance.post('/posts', postData);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  getPostsByInfluencers: async()=>{
    try {
      const response = await axiosInstance.get(`/posts/posts-by-influencers`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  getAllPosts: async () => {
    try {
      const response = await axiosInstance.get('/posts');
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getPostById: async (id) => {
    try {
      const response = await axiosInstance.get(`/posts/${id}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getPostByCityId: async (cityId) => {
    try {
      const response = await axiosInstance.get(`/posts/city/${cityId}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getPostByMarkerId: async (markerId) => {
    try {
      const response = await axiosInstance.get(`/posts/marker/${markerId}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getPostByLayerId: async (layerId) => {
    try {
      const response = await axiosInstance.get(`/posts/layer/${layerId}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  updatePost: async (id, postData) => {
    try {
      const response = await axiosInstance.put(`/posts/${id}`, postData);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  updatePostStatus: async (id, postData) => {
    try {
      const response = await axiosInstance.put(`/posts/approve/${id}`, postData);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  deletePost: async (id) => {
    try {
      const response = await axiosInstance.delete(`/posts/${id}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};

export default postService;
