import { FaSquarePen, FaTrash, FaArrowLeft, FaArrowRight, FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { FaChevronCircleLeft, FaTimes } from 'react-icons/fa'
import './SeePost.css';
import insta from './insta.svg';
import { useState } from 'react';
import DeletePost from './DeletePost';
import ApprovePost from './ApprovePost';
import RejectPost from './RejectPost';

const SeePost = ({ currentPost, onClose, onPrev, onNext, isOpen, postInfo, handlePostEditSuccess, handlePostDeleteSuccess, handlePostApproveSuccess, handlePostRejectSuccess }) => {
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(false)


    const handlePostDelete = () => {
        setIsDelete(true)
    }


    const handleCloseDelete = () => {
        setIsDelete(false)
    }


    const handleSuccessfulDelete = (post) => {
        setIsDelete(false)
        handlePostDeleteSuccess()
    }

    const [isApprove, setIsApprove] = useState(false);
    const [isReject, setIsReject] = useState(false);

    const handlePostApprove = () => setIsApprove(true);
    const handlePostReject = () => setIsReject(true);

    const handleCloseApprove = () => setIsApprove(false);
    const handleCloseReject = () => setIsReject(false);

    const handleSuccessfulApprove = (result) => {
        setIsApprove(false);
        handlePostApproveSuccess(result);
    };

    const handleSuccessfulReject = (result) => {
        setIsReject(false);
        handlePostRejectSuccess(result);
    };


    if (!isOpen) return null;
    const handleGetDirection = (link) => {
        if (link && typeof link === 'string') {
            window.open(link, '_blank'); // Open the direction link in a new tab
        } else {
            console.error("Invalid direction link provided:", link);
        }
    };
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-70 w-full h-full flex items-center justify-center">
            <div className="relative flex pointer-cursor h-3/4 bg-white">
                {currentPost.type == 'image' ? (
                    <img src={currentPost.fileUrl.large.imageUrl} className="w-auto h-full object-cover max-w-[50vw] min-w-[300px]" />
                ) : (
                    <video key={currentPost.fileUrl.large.imageUrl} controls loop autoPlay className="w-full h-full object-cover max-w-[50vw] min-w-[300px]">
                        <source src={currentPost.fileUrl.large.imageUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}


            </div>

            <div className="relative min-w-[300px] h-3/4 bg-white border-l-2 border-gray-400 flex flex-col">
                <div className="h-full p-4 flex-grow">
                    <p className="ml-2 flex items-center text-[20px] font-semibold">
                        {currentPost.markerName}
                    </p>
                    <p className="ml-2 mt-1 text-[18px] font-normal text-[#A2A2A2]">
                        {currentPost.cityName}
                    </p>
                    <p
                        className="profile-link ml-2 px-4 py-2 mt-2 cursor-pointer text-blue-500 hover:underline"
                        onClick={() => handleGetDirection(currentPost.markerDirection)}
                    >
                        Get Direction
                    </p>
                    <p className="ml-2 mt-1 text-[16px] font-normal text-[#A2A2A2]">
                        Status -{" "}
                        <span
                            className={
                                currentPost.status === "pending"
                                    ? "text-yellow-500"
                                    : currentPost.status === "approved"
                                        ? "text-green-500"
                                        : currentPost.status === "rejected"
                                            ? "text-red-500"
                                            : ""
                            }
                        >
                            {currentPost.status}
                        </span>
                    </p>
                </div>

                <div className="flex justify-center items-center h-64">
                    <button
                        className="flex items-center justify-center bg-green-500 text-white mr-4 rounded-full py-2 px-2 hover:bg-green-600"
                        onClick={handlePostApprove}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 mr-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        Approve
                    </button>

                    <button
                        className="flex items-center justify-center bg-red-500 text-white mr-4 rounded-full py-2 px-2 hover:bg-red-600"
                        onClick={handlePostReject}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 mr-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            ></path>
                        </svg>
                        Reject
                    </button>
                </div>

                <div className="flex justify-center space-x-4 p-4">
                    <button
                        className="action-button flex items-center"
                        onClick={handlePostDelete}
                    >
                        <FaTrash size={18} style={{ marginRight: "2px" }} />
                        Delete Post
                    </button>
                </div>
            </div>


            <button
                className="absolute top-4 right-4 text-white text-2xl p-2"
                onClick={onClose}>
                <FaTimes />
            </button>

            <button
                className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-black text-2xl p-2 bg-white rounded-full ${postInfo.index <= 0 && 'pointer-events-none opacity-50'}`}
                onClick={onPrev}>
                <FaChevronLeft />
            </button>

            <button
                className={`absolute right-4 top-1/2 transform -translate-y-1/2 text-black text-2xl p-2 bg-white rounded-full ${postInfo.index == postInfo.length - 1 && 'pointer-events-none opacity-50'}`}
                onClick={onNext}>
                <FaChevronRight />
            </button>



            <DeletePost
                isOpen={isDelete}
                onClose={handleCloseDelete}
                post={currentPost}
                onDeleteSuccess={handleSuccessfulDelete}
            />
            <ApprovePost
                isOpen={isApprove}
                post={currentPost}
                onClose={handleCloseApprove}
                onApproveSuccess={handleSuccessfulApprove}
            />

            <RejectPost
                isOpen={isReject}
                post={currentPost}
                onClose={handleCloseReject}
                onRejectSuccess={handleSuccessfulReject}
            />
        </div>
    );
}

export default SeePost;
